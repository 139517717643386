<template>
    <div class="Wrapper404">
        <div class="textWrap">
            <h1>404 <span>Помилка</span></h1>
            <p>Ця сторінка знаходиться на стадії розробки, спробуйте завітати на цю сторінку пізніше!</p>
            <router-link :to="'/'" class="btn">На головну</router-link>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
    export default {
        name: 'NotFound',
        methods: {
            ...mapActions(["unlockUi"])
        },
        mounted () {
            this.unlockUi();
        },
    }
</script>

<style lang="sass" scoped>
.Wrapper404
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
.textWrap
    max-width: 362px
    color: #fff
    h1
        font-size: 91px
        line-height: 100%
        font-weight: bold
        margin-bottom: 10px
        span
            font-size: 39px
            line-height: 100%
            font-weight: normal
    p
        font-weight: 300
.btn
    width: 100%
    text-align: center
</style>
